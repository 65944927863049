<!-- eslint-disable -->
<template>
  <v-container style="padding: 0">
    <v-row class="text-left">
      <v-col class="">
        <template>
          <v-card>
            <v-card-title>
              Мое меню
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              :hide-default-footer="true"
              :items-per-page="100"
              class="elevation-1"
              group-by="gsort"
              show-group-by
              mobile-breakpoint="0"
            >
              <template v-slot:group.header="{ items, isOpen, toggle }">
                <th colspan="4">
                  <v-icon @click="toggle"
                    >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                  </v-icon>
                  {{ items[0].type }}
                </th>
              </template>
              <template v-slot:item.name="{ item }"
                ><span>{{ item.name }}</span>
                <span class="text-caption font-italic"
                  >{{ item.weight }}г</span
                ></template
              >
              <template v-slot:item.price="{ item }"
                ><span>{{ item.price }}&nbsp;₽</span></template
              >

              <template v-slot:item.order="{ item }">
                <v-row>
                  <v-icon color="green" @click="plusItem(item)">
                    mdi-plus-circle
                  </v-icon>
                  <span class="mx-1" v-if="item.order > 0">{{
                    item.order
                  }}</span
                  ><v-icon
                    v-if="item.order > 0"
                    color="gray"
                    @click="minusItem(item)"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </template>
        <span> - </span>
        <v-card class="pa-4 text-right" v-if="orderList">
          <span
            style="display: block"
            v-for="line in orderList"
            class="text-right"
            >{{ line.name }}: {{ line.order }}</span
          >
        </v-card>

        <v-card v-if="orderTotal > 0">
          <v-card-title>
            <v-spacer></v-spacer>

            <span v-if="orderTotal < 1000" class="red--text"
              >Доставка платная! 100 ₽<br /><span
                class="text-caption font-italic"
              >
                На заказы до 1000 ₽
              </span></span
            >
            <span v-if="orderTotal >= 1000" class="green--text"
              >Доставим бесплатно 🙂</span
            >
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title>
            <v-text-field
              v-model="client"
              append-icon="mdi-account"
              label="Клиент"
              :disabled="($route.query.c)?true:false"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" color="primary" @click="send()" dark>
              Заказ подтверждаю
              <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
}
</style>
  

<script>
export default {
  name: "HelloWorld",
  methods: {
    plusItem(item) {
      let editedIndex = this.desserts.indexOf(item);
      this.desserts[editedIndex].order++;
    },
    minusItem(item) {
      let editedIndex = this.desserts.indexOf(item);
      if (this.desserts[editedIndex].order > 0) {
        this.desserts[editedIndex].order--;
      }
    },
    async send() {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          operationName: "order",
          variables: {
            order: {
              client: this.client,
              items: this.orderList,
            },
          },
          query:
            "mutation order($order: JSON) {\n  newOrder(order: $order)\n}\n",
        }),
      };
      const response = await fetch(
        "https://functions.yandexcloud.net/d4eovbddp2h2uvi2e7ab",
        requestOptions
      );
      const data = await response.json();
      console.log(data);
    },
    getClient() {
      //  if(t)
      this.client = Buffer.from(this.$route.query.c, "base64").toString(
        "utf8"
      );
    },
  },

  beforeMount() {
    this.getClient();
  },
  // props: ["client"],
  computed: {
    // геттер вычисляемого значения
    orderList: function () {
      let items = this.desserts.filter((item) => item.order > 0);
      return items;
    },

    orderTotal: function () {
      return this.orderList.reduce((acc, val) => {
        return (acc += val.order * val.price);
      }, 0);
    },
  },

  data: () => ({
    search: "",
    client: "",
    count: 0,
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: "Блюдо",
        align: "start",
        sortable: false,
        groupable: false,
        value: "name",
      },
      // { text: "Вес (г)", value: "weight", groupable: false },
      { text: "Цена", value: "price", groupable: false },
      {
        text: "Заказ",
        value: "order",
        groupable: false, //, width: "20%"
      },
    ],
    desserts: [
      {
        id: "Завтраки1",
        name: "Нежные сырники с малиновым соусом/сметаной",
        weight: 180,
        price: 200,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки2",
        name: "Сырники из топленой рикотты с соленой карамелью",
        weight: 150,
        price: 220,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки3",
        name: "Запеканка из обезжиренного творога с солнечными персиками и семенами чиа",
        weight: 200,
        price: 220,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки4",
        name: "Воздушная творожная запеканка с кокосовой сгущенкой",
        weight: 200,
        price: 200,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки5",
        name: "Блинчики на рисовой муке с творогом",
        weight: 140,
        price: 170,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки6",
        name: "Блинчики на рисовой муке с начинкой Жюльен",
        weight: 180,
        price: 200,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки7",
        name: "Рисовые блины с лососем и творожным сыром",
        weight: 140,
        price: 270,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки8",
        name: "Воздушный омлет с четырьмя видами зелени, белым сыром и вялеными томатами черри",
        weight: 140,
        price: 190,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки9",
        name: "Омлет со слабосоленым лососем",
        weight: 140,
        price: 230,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки10",
        name: "Скрэмбл со шпинатом и креветками",
        weight: 200,
        price: 240,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки11",
        name: "Драники из цукини со слабосоленым лососем и яйцом",
        weight: 180,
        price: 270,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Завтраки12",
        name: "Каши в ассортименте (уточнять кашу дня)",
        weight: 250,
        price: 180,
        type: "Завтраки",
        gsort: 1,
        order: 0,
      },
      {
        id: "Перекусы1",
        name: "Бургер с курицей и овощами (булочка с чернилами каракатицы)",
        weight: 180,
        price: 280,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы2",
        name: "Сэндвич с курицей и сыром",
        weight: 240,
        price: 250,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы3",
        name: "Сэндвич с лососем",
        weight: 240,
        price: 280,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы4",
        name: "Буррито с куриным филе и кунжутным соусом",
        weight: 200,
        price: 220,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы5",
        name: "Буррито с лососем и кокосовым соусом",
        weight: 200,
        price: 280,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы6",
        name: "Буррито с креветками и соусом сладким чили",
        weight: 200,
        price: 300,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Перекусы7",
        name: "Буррито с говядиной и соусом табаско",
        weight: 200,
        price: 280,
        type: "Перекусы",
        gsort: 2,
        order: 0,
      },
      {
        id: "Салаты1",
        name: "Цезарь с курицей",
        weight: 200,
        price: 250,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты2",
        name: "Салат греческий",
        weight: 250,
        price: 250,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты3",
        name: "Салат с куриным филе и сыром моцарелла",
        weight: 200,
        price: 260,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты4",
        name: "Салат с цыпленком и печеными овощами",
        weight: 200,
        price: 240,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты5",
        name: "Запеченная свекла с творожным сыром в медово-горчичной заправке",
        weight: 200,
        price: 200,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты6",
        name: "Боул с киноа и овощами",
        weight: 280,
        price: 300,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты7",
        name: "Спагетти RAW с бобами эдамаме и фермерским цыпленком гриль",
        weight: 250,
        price: 280,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты8",
        name: "Салат с ростбифом и маринованными водорослями чука под ореховым соусом",
        weight: 200,
        price: 280,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты9",
        name: "Роллы с авокадо и творожным сыром",
        weight: 180,
        price: 220,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты10",
        name: "Роллы с лососем и вялеными томатами",
        weight: 180,
        price: 290,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты11",
        name: "Салат с  горбушей и овощами в медово-горчичной заправке",
        weight: 200,
        price: 240,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Салаты12",
        name: "Салат с  лососем и авокадо в медово-горчичной заправке",
        weight: 200,
        price: 290,
        type: "Салаты",
        gsort: 3,
        order: 0,
      },
      {
        id: "Обеды1",
        name: "Суп-крем тыквенный",
        weight: 250,
        price: 200,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды2",
        name: "Суп-крем из шампиньонов с сухариками",
        weight: 250,
        price: 220,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды3",
        name: "Бефстроганов из телятины с гречкой",
        weight: 250,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды4",
        name: "Тальятелле с лососем в сливочном соусе",
        weight: 200,
        price: 340,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды5",
        name: "Соба с куриным филе и гречневой лапшой",
        weight: 200,
        price: 260,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды6",
        name: "WOK с креветками и гречневой лапшой",
        weight: 200,
        price: 380,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды7",
        name: "Стейк из лосося с сальсой из авокадо и манго на подушке из киноа",
        weight: 200,
        price: 450,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды8",
        name: "Стейк из лосося в медово-горчичном маринаде с рисом микс и овощами",
        weight: 200,
        price: 420,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды9",
        name: "Котлетка из индейки с овощами wok",
        weight: 250,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды10",
        name: "Горбуша под маринадом с картофельными дольками",
        weight: 250,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды11",
        name: "Сливочное ризотто с цыпленком и белыми грибами",
        weight: 200,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды12",
        name: "Куриные оладушки с сырным соусом и булгур с овощами",
        weight: 250,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды13",
        name: "Шашлычок из телятины с овощами гриль",
        weight: 200,
        price: 360,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Обеды14",
        name: "Люля кебаб из цесарки с картофельными дольками",
        weight: 250,
        price: 250,
        type: "Обеды",
        gsort: 4,
        order: 0,
      },
      {
        id: "Десерты1",
        name: "Чизкейк а-ля сникерс",
        weight: 120,
        price: 240,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Десерты2",
        name: "Творожный чизкейк",
        weight: 120,
        price: 220,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Десерты3",
        name: "Карамелизированная груша с орехами и медом",
        weight: 140,
        price: 200,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Десерты4",
        name: "Чиа Jungle (семена чиа, пюре из тропических фруктов, кокосовые чипсы и гранола)",
        weight: 200,
        price: 250,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Десерты5",
        name: "Безглютеновый миндальный тарт",
        weight: 120,
        price: 240,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Десерты6",
        name: "Морковный торт",
        weight: 80,
        price: 220,
        type: "Десерты",
        gsort: 5,
        order: 0,
      },
      {
        id: "Напитки1",
        name: "Смузи",
        weight: 300,
        price: 300,
        type: "Напитки",
        gsort: 6,
        order: 0,
      },
      {
        id: "Напитки2",
        name: "Детокс вода",
        weight: 300,
        price: 100,
        type: "Напитки",
        gsort: 6,
        order: 0,
      },
    ],
  }),
};
</script>
