var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',{staticClass:"text-left"},[_c('v-col',{},[[_c('v-card',[_c('v-card-title',[_vm._v(" Мое меню "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"hide-default-footer":true,"items-per-page":100,"group-by":"gsort","show-group-by":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"4"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].type)+" ")],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"text-caption font-italic"},[_vm._v(_vm._s(item.weight)+"г")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" ₽")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.plusItem(item)}}},[_vm._v(" mdi-plus-circle ")]),(item.order > 0)?_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(item.order))]):_vm._e(),(item.order > 0)?_c('v-icon',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.minusItem(item)}}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e()],1)]}}])})],1)],_c('span',[_vm._v(" - ")]),(_vm.orderList)?_c('v-card',{staticClass:"pa-4 text-right"},_vm._l((_vm.orderList),function(line){return _c('span',{staticClass:"text-right",staticStyle:{"display":"block"}},[_vm._v(_vm._s(line.name)+": "+_vm._s(line.order))])}),0):_vm._e(),(_vm.orderTotal > 0)?_c('v-card',[_c('v-card-title',[_c('v-spacer'),(_vm.orderTotal < 1000)?_c('span',{staticClass:"red--text"},[_vm._v("Доставка платная! 100 ₽"),_c('br'),_c('span',{staticClass:"text-caption font-italic"},[_vm._v(" На заказы до 1000 ₽ ")])]):_vm._e(),(_vm.orderTotal >= 1000)?_c('span',{staticClass:"green--text"},[_vm._v("Доставим бесплатно 🙂")]):_vm._e()],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-account","label":"Клиент","disabled":(_vm.$route.query.c)?true:false},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Заказ подтверждаю "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-checkbox-marked-circle ")])],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }