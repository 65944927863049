<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Food4U"
          class="shrink mr-2"
          contain
          src="https://static.tildacdn.com/tild3631-6233-4265-b839-613231383362/logo_F4U__1.png"
          transition="scale-transition"
          width="100"
        />

       
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="bgr">
    
       <router-view/>
    </v-main>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

 

  data: () => ({
    //
  }),
};
</script>


<style>

.bgr {
    background-image: url("./assets/bgr.jpeg");
    background-repeat: no-repeat;
    background-position: calc(100%) calc(100% - 0px);
    background-attachment: fixed;

}

</style>